<template>
  <v-container>
    <v-row justify="center">
      <h2>Volkswagen Golf 7.5 GTI</h2>
    </v-row>
    <v-row class="ma-10 d-none d-lg-flex" justify="center" no-gutters>
      <v-timeline
          :dense="$vuetify.breakpoint.mdAndDown"
      >
        <div v-for="card in cards" :key="card.id">
          <v-timeline-item
              fill-dot
              color="red"
          >
            <span slot="opposite">{{ card.timeline_title }}</span>
            <v-card class="elevation-4">
              <v-card-title class="headline">
                {{ card.title }}
              </v-card-title>
              <v-card-text>
                <v-img v-for="image in card.images" :key="image.id"
                       :src=image.src
                       :lazy-src=image.lazy_src
                       class="my-4"
                />
                <p v-html="card.content" />
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </div>
      </v-timeline>
    </v-row>

    <v-row class="ma-10 d-sm-flex d-md-flex d-lg-none d-xl-none"  justify="center" no-gutters>
      <timeline>
        <div v-for="card in cards" :key="card.id">
          <timeline-title font-color=""><h2>{{ card.timeline_title }}</h2></timeline-title>
          <timeline-item font-color="" :hollow="true">
            <h3>{{card.title}}</h3>
            <v-img v-for="image in card.images" :key="image.id"
                   :src=image.src
                   :lazy-src=image.lazy_src
                   max-width="80vw"
                   class="my-4"
            />
            <p v-html="card.content" />
          </timeline-item>
        </div>
      </timeline>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'

export default {
  name: 'Gti',
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle
  },
  data: () => ({
    cards: [
      {
        id: 1,
        timeline_title: "Aprill 2017",
        title: "GTI on tehasest tellitud",
        content: ""
      },
      {
        id: 2,
        timeline_title: "Juuni 2017",
        title: "GTI jõuab Tartusse",
        images: [
          {
            id: 1,
            src: "images/GTI/IMG_0832.jpeg",
            lazy_src: "images/GTI/thumb/IMG_0832.jpeg"
          }
        ],
        content: ""
      },
      {
        id: 3,
        timeline_title: "Juuni 2017 - Aprill 2021",
        title: "GTI sõidab mööda Eestit ringi",
        images: [
          {
            id: 1,
            src: "images/GTI/IMG_1181.jpeg",
            lazy_src: "images/GTI/thumb/IMG_1181.jpeg"
          },
          {
            id: 2,
            src: "images/GTI/IMG_1530.jpeg",
            lazy_src: "images/GTI/thumb/IMG_1530.jpeg"
          },
          {
            id: 3,
            src: "images/GTI/IMG_1703.jpeg",
            lazy_src: "images/GTI/thumb/IMG_1703.jpeg"
          },
          {
            id: 4,
            src: "images/GTI/IMG_1858.jpeg",
            lazy_src: "images/GTI/thumb/IMG_1858.jpeg"
          },
          {
            id: 5,
            src: "images/GTI/IMG_2136.jpeg",
            lazy_src: "images/GTI/thumb/IMG_2136.jpeg"
          },
          {
            id: 6,
            src: "images/GTI/mets.jpeg",
            lazy_src: "images/GTI/thumb/mets.jpeg"
          },
          {
            id: 7,
            src: "images/GTI/muru.jpeg",
            lazy_src: "images/GTI/thumb/muru.jpeg"
          }
        ],
        content: ""
      },
      {
        id: 4,
        timeline_title: "Aprill 2021",
        title: "GTI on maha müüdud",
        images: [],
        content: "23. aprillil on vara üle andmise akt minu poolt allkirjastatud ning jäänud on vaid uuel omanikul\n" +
            "              kõik paberid korda ajada ning järgi tulla.\n" +
            "              <br /><br />\n" +
            "              26. aprilli õhtul on uus omanik Tartus GTI'l järel ning Tallinna poole teel ta ongi ..."
      },
    ]
  })
}
</script>
